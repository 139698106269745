import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/esm/Container";
import Slider from "react-slick";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import ProductCard from "../../../components/ProductCard/ProductCard";
import productsAction from "../../../../redux/actions/productsAction";
import Loader from "../../../components/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Timer from "./Timer/Timer";
import "./HotDeals.css";

let settings = {
  infinite: true,
  speed: 1500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1760,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1360,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 935,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="deals_btn deals_next_btn" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="deals_btn deals_prev_btn" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
}

const HotDeals = () => {
  const dispatch = useDispatch();

  const { loading, products } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(productsAction().getProducts("all", 1, "What's New", 5));
  }, [dispatch]);

  return (
    <section className="hot_deals_section section_spacing">
      <SectionTitle
        title="Hot Deals"
        subtitle="Add hot products to weekly line up"
      />
      <Container fluid className="deals_slider_container">
        <Timer />
        <Slider {...settings}>
          {loading ? (
            <Loader />
          ) : (
            products &&
            products.map((product, index) => (
              <ProductCard product={product} key={index} />
            ))
          )}
        </Slider>
      </Container>
    </section>
  );
};

export default HotDeals;
