import React, { useEffect, useState } from "react";
import moment from "moment";
import "./Timer.css";

const Timer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const date = moment("12:00:00 a", "hh:mm:ss a");
  const todayDate = moment().format();
  const deadline = date.add(1, "d").format();

  useEffect(() => {
    const getTime = () => {
      const time = Date.parse(deadline) - Date.parse(todayDate);

      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };

    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, [deadline, todayDate]);

  return (
    <div className="timer">
      <h3>deal of the day</h3>
      <div className="timer_container">
        <div className="days">
          <h3 id="days">{days}</h3>
          <p>days</p>
        </div>
        <span>:</span>
        <div className="hours">
          <h3 id="hours">{hours}</h3>
          <p>hrs</p>
        </div>
        <span>:</span>
        <div className="minutes">
          <h3 id="minutes">{minutes}</h3>
          <p>mins</p>
        </div>
        <span>:</span>
        <div className="seconds">
          <h3 id="seconds">{seconds}</h3>
          <p>secs</p>
        </div>
      </div>
    </div>
  );
};

export default Timer;
