import React from "react";
import NotFoundImg from "../../../images/404.png";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <section className="page_not_found d-flex align-items-center">
      <div className="container text-center py-5">
        <h4 className="fw-bold">Sorry, Page Not Found! 😕</h4>
        <p className="text-muted">
          You probably entered wrong url. To visit website, go to the main page.
        </p>
        <img className="d-block mx-auto my-4" src={NotFoundImg} alt="" />
        <a href="/" className="btn btn_orange go_back_btn">
          Go Back
        </a>
      </div>
    </section>
  );
};

export default PageNotFound;
