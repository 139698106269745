import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import HeroCarousel1 from "../../../../images/hero-carousel-1.jpg";
import HeroCarousel2 from "../../../../images/hero-carousel-2.jpg";
import "./HeroCarousel.css";

let carouselDetails = [
  {
    image: HeroCarousel1,
    title: "What you are looking for",
    subTitle: "Perfect Gift's ?",
    detail:
      "Our extensive selection of unique and thoughtful gifts is sure to impress even the pickiest of recipients",
  },
  {
    image: HeroCarousel2,
    title: "What you are looking for",
    subTitle: "Perfect Gift's ?",
    detail:
      "We have something for everyone, From personalized gifts to luxurious indulgences",
  },
];

const HeroCarousel = () => {
  return (
    <section className="hero_carousel_section">
      <Carousel>
        {carouselDetails.map((carousel, index) => (
          <Carousel.Item key={index}>
            <div className="carousel_overlay"></div>
            <img
              className="d-block w-100"
              src={carousel.image}
              alt="First slide"
            />
            <Carousel.Caption>
              <h1>{carousel.title}</h1>
              <h1>{carousel.subTitle}</h1>
              <p>{carousel.detail} </p>
              <Link to="/products/all">SHOP NOW</Link>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
};

export default HeroCarousel;
