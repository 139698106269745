import React from "react";
import { NavLink } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/esm/Container";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import Blog_1 from "../../../../images/Blog_1.jpg";
import Blog_2 from "../../../../images/Blog_2.jpg";
import Blog_3 from "../../../../images/Blog_3.jpg";
import "./Blogs.css";

const blogDetails = [
  {
    id: 1,
    image: Blog_1,
    title: "Summer gift Ideas for your loved ones!",
    details:
      "Summer is the season of sunshine, warm weather, and fun outdoors activities. It's also the perfect time to show your loved ones how much you care by giving them a thoughtful and practical summer gift.",
    paragraph: [
      {
        heading: "Beach Gear",
        content:
          "Summer is synonymous with the beach, and there's no better gift than beach gear. From a stylish beach bag to a cool beach towel, sunglasses, and sunscreen, these essentials are perfect for anyone who loves to soak up the sun.",
      },
      {
        heading: "Outdoor Games",
        content:
          "Summer is all about fun in the sun, and outdoor games are a great way to get everyone involved. From a classic game of Frisbee to a cornhole set, these games are perfect for the beach, park, or backyard.",
      },
      {
        heading: "Portable Bluetooth Speakers",
        content:
          "Whether it's for a picnic or a day at the beach, portable Bluetooth speakers are a must-have for anyone who loves music. With a range of options available, you can choose from waterproof speakers, solar-powered options, or ones that double as a power bank.",
      },
      {
        heading: "Cooler",
        content:
          "A cooler is an essential summer gift for anyone who loves to spend time outdoors. From a stylish Yeti cooler to a practical backpack cooler, these gifts are perfect for picnics, camping trips, and beach days.",
      },
      {
        heading: "Water Bottles",
        content:
          "Staying hydrated in the summer is essential, and a reusable water bottle is a practical and eco-friendly gift. Choose from a range of options, including insulated bottles, collapsible bottles, or ones with built-in filters.",
      },
      {
        content:
          "In conclusion, there are endless options for summer gifts that are sure to make your loved ones smile. From beach gear to outdoor games, portable speakers to coolers, and water bottles to portable fans these gifts are perfect for anyone who loves to spend time in the sun. So why not surprise your loved ones with a thoughtful and practical summer gift today?",
      },
    ],
  },
  {
    id: 2,
    image: Blog_2,
    title: "6 Meaningful Tips for Writing Heartfelt Cards",
    details:
      "In the digital age, it's easy to send a quick message or email to communicate with our loved ones. However, there's something special about taking the time to write a card and send it through the mail.",
    paragraph: [
      {
        heading: "Start with a Greeting",
        content:
          'The opening of your card sets the tone for the rest of your message. Start with a warm and friendly greeting, such as "Dear [Name]," "Hi [Name]," or "Hello [Name]." This will help to establish a personal connection with the recipient right from the start.',
      },
      {
        heading: "Express Your Sentiments",
        content:
          "Take some time to think about what you want to say and how you feel about the person you are writing to. Whether it's expressing gratitude, sharing your love, or simply wishing them well, be sincere and honest in your sentiments.",
      },
      {
        heading: "Keep it Concise",
        content:
          "While it's important to express your thoughts and feelings, it's also important to keep your message concise. Try to limit your message to a few sentences or a short paragraph, so that it's easy for the recipient to read and understand.",
      },
      {
        heading: "Add a Personal Touch",
        content:
          "A handwritten card is a great opportunity to add a personal touch to your message. Consider adding a doodle or drawing, or even including a small gift or memento that has special meaning to the recipient.",
      },
      {
        heading: "Close with Warmth",
        content:
          'End your card with a warm closing, such as "Love," "Sincerely," or "Best Wishes." This will help to reinforce the sentiment of your message and leave a lasting impression on the recipient.',
      },
      {
        heading: "Proofread and Sign",
        content:
          "Before sending your card, take a moment to proofread it for any spelling or grammatical errors. Once you're satisfied with your message, sign your name and date the card, so that the recipient knows when you sent it.",
      },
      {
        content:
          "In conclusion, taking the time to write a heartfelt card is a simple but meaningful way to show your loved ones that you care. With these card writing tips, you can make your message more sincere, personal, and memorable. So why not grab a pen and some paper, and start writing your next card today?",
      },
    ],
  },
  {
    id: 3,
    image: Blog_3,
    title: "Tips and Ideas for Elevating Your Space",
    details:
      "Elevate your living space with our high-quality furniture. As a leading brand, we understand the importance of creating a comfortable and stylish home environment, and we are here to help you achieve that.",
    paragraph: [
      {
        content:
          "Whether you are moving into a new home or looking to refresh your current space, our furniture pieces are designed to enhance the aesthetics and functionality of your living area. From sofas to lights, dining accessories to wall art, we have a wide range of furniture options to suit every taste and requirement.",
      },
      {
        content:
          "In this blog, we will share some of our favorite home decor ideas that you can implement using our furniture pieces. From selecting the right color palette to accessorizing your space, we have got you covered.",
      },
      {
        heading: "Color Coordination",
        content:
          "One of the easiest ways to give your home a new look is by playing with colors. Selecting the right color palette can set the tone for your entire space. Our furniture pieces come in a variety of colors, ranging from earthy neutrals to bold and vibrant hues. Consider selecting a color palette that reflects your personality and complements the rest of your home decor.",
      },
      {
        heading: "Accessories",
        content:
          "Accessories can make or break a room. Adding a few carefully selected pieces can enhance the overall look and feel of your space. Our home decor accessories include accent pillows, rugs, curtains, and more. These accessories can add texture and dimension to your living area and tie the entire room together.",
      },
      {
        heading: "Lighting",
        content:
          "Lighting is an essential aspect of home decor that often gets overlooked. The right lighting can set the mood and create a cozy ambiance in your space. Our lighting collection includes table lamps, floor lamps, and pendant lights that are not only functional but also add an element of style to your room.",
      },
      {
        heading: "Storage Solutions",
        content:
          "Clutter can make even the most beautiful space feel chaotic and disorganized. Our furniture pieces include storage solutions that not only keep your space tidy but also add to the overall aesthetics. From bookshelves to sideboards, our storage options are designed to blend seamlessly with your home decor.",
      },
      {
        content:
          "In conclusion, our furniture pieces are not only functional but also designed to enhance the aesthetics of your home.",
      },
    ],
  },
];

const Blogs = () => {
  return (
    <section className="blog_section section_spacing">
      <SectionTitle
        title="Latest Blogs"
        subtitle="Present posts in a best way to highlight interesting moments of your
        blog."
      />
      <Container fluid>
        <Row xs={1} sm={2} md={2} lg={3} className="g-4">
          {blogDetails.map((blog, index) => (
            <Col key={index}>
              <Card className="blog_card" key={blog.id}>
                <div className="blog_img">
                  <Card.Img variant="top" src={blog.image} />
                </div>
                <Card.Body className="blog_details">
                  <Card.Title>{blog.title}</Card.Title>
                  <Card.Text>{blog.details}</Card.Text>
                </Card.Body>
                <Card.Body id="blog_details" className="hide">
                  <Card.Text>{blog.details}</Card.Text>
                </Card.Body>
                <Card.Footer>
                  <NavLink
                    to={`/blog/${blog.id}`}
                    state={{ data: blog }}
                    className="read_more"
                  >
                    <span>Read More</span>
                    <FontAwesomeIcon icon={faPlayCircle} />
                  </NavLink>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Blogs;
