import React from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

const Banner = ({ images }) => {
  return (
    <CardGroup className="section_spacing">
      {images &&
        images.map((img, index) => (
          <Card className="mx-3" key={index}>
            <Card.Img src={img} />
          </Card>
        ))}
    </CardGroup>
  );
};

export default Banner;
