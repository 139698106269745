import React from "react";
import LoaderGIF from "../../../images/loader.gif";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader">
      <img src={LoaderGIF} alt="" />
      <div className="progress">
        <div className="color"></div>
      </div>
    </div>
  );
};

export default Loader;
