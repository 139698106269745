import { applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  allProductsReducer,
  productDetailsReducer,
} from "./reducers/productsReducer";

let initialState = {};
const middleware = [thunk];

const store = configureStore(
  {
    reducer: {
      products: allProductsReducer,
      productDetails: productDetailsReducer,
    },
  },
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
