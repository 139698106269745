import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import Router from "./Router";
import Header from "./views/components/Header/Header";
import Footer from "./views/components/Footer/Footer";
import Logo from "./images/logo.gif";
import ScrollToTop from "./views/components/ScrollToTop/ScrollToTop";
import "./App.css";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="loading">
          <img src={Logo} alt="logo" />
        </div>
      ) : (
        <BrowserRouter>
          <Header />
          <ScrollToTop>
            <Router />
          </ScrollToTop>
          <Footer />
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
