import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Slider from "react-slick";
import productsAction from "../../../redux/actions/productsAction";
import Loader from "../../components/Loader/Loader";
import Rating from "../../components/Rating/Rating";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./ProductDetails.css";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slick_carousel_btn slick_carousel_next_btn"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slick_carousel_btn slick_carousel_prev_btn"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
}

const ProductDetails = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { loading, product } = useSelector((state) => state.productDetails);

  useEffect(() => {
    dispatch(productsAction().getProductDetails(id));
  }, [dispatch, id]);

  const settings = {
    customPaging: function (i) {
      return <img src={product.images[i].url} alt="" />;
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 1000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <section className="product_details_section">
      {loading ? (
        <Loader />
      ) : (
        <Container fluid>
          <Slider {...settings} className="slider_carousel_container">
            {product.images &&
              product.images.length > 0 &&
              product.images.map((img, index) => (
                <div className="product_img" key={index}>
                  <img src={img.url} alt="" />
                </div>
              ))}
          </Slider>
          <div className="product_details">
            <div>
              <Badge className="category_tag me-3">{product.category}</Badge>
              <Badge className="sub_category_tag">{product.subcategory}</Badge>
            </div>
            <h1 className="title my-3 fw-bold">{product.name}</h1>
            <Rating rating={product.rating} />
            <div className="price">
              <span className="discount text-success fw-bold">
                - {product.discount}% off
              </span>
              <div>
                <p>
                  &#x20b9;
                  {Math.round(
                    product.price - (product.price * product.discount) / 100
                  )}
                </p>
                <s>&#x20b9;{product.price}</s>
              </div>
            </div>
            <p className="description">{product.description}</p>
            <p className="size">
              <span>Size / Dimensions : </span>
              {product.dimensions}
            </p>
            <p className="colour">
              <span>Colours : {product.colours}</span>
            </p>
          </div>
        </Container>
      )}
    </section>
  );
};

export default ProductDetails;
