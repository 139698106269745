import React from "react";
import Container from "react-bootstrap/Container";
import { Link, NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import logo from "../../../images/logo.png";
import "./Header.css";

const navItems = [
  { key: "Home", value: "/" },
  { key: "Products", value: "/products/all" },
  {
    key: "Category",
    subNavItems: [
      {
        key: "Furniture",
        value: "/products/furniture",
      },
      {
        key: "Gifts For Him",
        value: "/products/gifts-for-him",
      },
      {
        key: "Gifts For Her",
        value: "/products/gifts-for-her",
      },
      {
        key: "Decore",
        value: "/products/decore",
      },
      {
        key: "Lighting",
        value: "/products/lighting",
      },
      {
        key: "Games",
        value: "/products/games",
      },
      {
        key: "Cards",
        value: "/products/cards",
      },
      {
        key: "Chocolates",
        value: "/products/chocolates",
      },
      {
        key: "Stationery",
        value: "/products/stationery",
      },
      {
        key: "Beauty",
        value: "/products/beauty",
      },
      {
        key: "Personal Care",
        value: "/products/personal-care",
      },
      {
        key: "Figurine",
        value: "/products/figurine",
      },
    ],
  },
  { key: "About Us", value: "/about-us" },
];

function Header() {
  return (
    <header>
      {/* -------------------- Topbar Start -------------------- */}
      <div className="topbar">
        <Container fluid className="topbar_container">
          <div className="icons">
            <Link to="https://www.facebook.com/WorldsGiftMall/">
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link to="https://www.instagram.com/worldsgiftmall_150_ring_road/">
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
          </div>
          <div className="contact_info">
            <div className="phone">
              <FontAwesomeIcon icon={faPhone} />
              <Link to="#">0281-2475911</Link>
            </div>
            <span className="divider">|</span>
            <div className="mail">
              <FontAwesomeIcon icon={faEnvelope} />
              <Link to="#">info@worldsgiftcard.com</Link>
            </div>
          </div>
        </Container>
      </div>
      {/* -------------------- Topbar End -------------------- */}

      {/* -------------------- Navbar Start -------------------- */}
      <Navbar expand="md" bg="light">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <img src={logo} alt="" className="navbar_logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" />
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-md"
            aria-labelledby="offcanvasNavbarLabel-expand-md"
            placement="end"
          >
            <Offcanvas.Header closeButton />
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1">
                {navItems.map((item, index) =>
                  item.key !== "Category" ? (
                    <NavLink to={item.value} key={index} className="nav_link">
                      {item.key}
                    </NavLink>
                  ) : (
                    <NavDropdown title={item.key} id="basic-nav-dropdown">
                      {item.subNavItems.map((val, index) => (
                        <NavDropdown.Item href={val.value} key={index}>
                          {val.key}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  )
                )}
              </Nav>
              <div className="mobile_divider"></div>
              <div className="mobile_topbar">
                <div className="icons">
                  <h6 className="fw-bold">Follow Us:</h6>
                  <Link to="https://www.facebook.com/WorldsGiftMall/">
                    <FontAwesomeIcon icon={faFacebook} />
                  </Link>
                  <Link to="https://www.instagram.com/worldsgiftmall_150_ring_road/">
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                </div>
                <div className="contact_info mt-3">
                  <h6 className="fw-bold">Contact Us:</h6>
                  <div className="phone">
                    <FontAwesomeIcon icon={faPhone} />
                    <p>0281-2475911</p>
                  </div>
                  <div className="mail">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <p>info@worldsgiftcard.com</p>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {/* -------------------- Navbar End -------------------- */}
    </header>
  );
}

export default Header;
