import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import PageTitle from "../../components/PageTitle/PageTitle";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <section className="about_us_page">
      <Container fluid>
        <PageTitle details={{ title: "About Us", breadcrumb: "About Us" }} />
        <Card className="about_us_details">
          <Card.Body>
            <Card.Text>
              Welcome to our gift mall, the largest and most prestigious gift
              store in Rajkot. We are owned and operated by Rajesh Parekh and
              Bhavin Parekh, who have been serving the community for many years.
            </Card.Text>
            <Card.Text>
              Our gift mall is dedicated to offering a wide range of
              high-quality gift items for all occasions. We are passionate about
              providing unique and personalized gifts that create lasting
              memories and bring joy to the lives of our customers.
            </Card.Text>
            <Card.Text>
              Our team of expert staff is committed to delivering excellent
              customer service, ensuring that every customer has a satisfying
              shopping experience.
            </Card.Text>
            <Card.Text>
              As a family-owned business, we take pride in our commitment to the
              community. We support local charities and regularly participate in
              community events. Our goal is to make a positive impact on the
              lives of our customers and contribute to the betterment of the
              community.
            </Card.Text>
            <Card.Text>
              We understand the importance of gift-giving and the role it plays
              in strengthening relationships. That's why we have made it our
              mission to provide a warm and welcoming environment for our
              customers, where they can find the perfect gift for their loved
              ones.
            </Card.Text>
            <Card.Text>
              We invite you to visit our store and experience the joy of
              gift-giving. Our team of friendly and knowledgeable staff is ready
              to assist you with any questions or concerns you may have.
            </Card.Text>
          </Card.Body>
        </Card>
        <div className="vision_mission">
          <Card>
            <Card.Body>
              <Card.Title>
                <b>Vision</b>
              </Card.Title>
              <Card.Text>
                Our vision is to become the go-to destination for anyone looking
                to buy thoughtful, unique and personalized gifts for their loved
                ones. We strive to be recognized as the best gift mall in
                Rajkot, offering a diverse range of products that cater to all
                tastes and preferences.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Mission</Card.Title>
              <Card.Text>
                To provide our customers with an exceptional shopping experience
                by offering high-quality, affordable and personalized gift items
                that create lasting memories. We are committed to delivering
                unparalleled customer service and creating a warm and welcoming
                environment that makes gift-giving a joyous and memorable
                experience.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </section>
  );
};

export default AboutUs;
