import React from "react";
import HeroCarousel from "./HeroCarousel/HeroCarousel";
import PopularCategories from "./PopularCategories/PopularCategories";
import NewArrivals from "./NewArrivals/NewArrivals";
import HotDeals from "./HotDeals/HotDeals";
import Banner from "../../components/Banner/Banner";
import Banner_1 from "../../../images/banner-1.png";
import Banner_2 from "../../../images/banner-2.png";
import Banner_3 from "../../../images/banner-3.png";
import Banner_5 from "../../../images/banner-5.png";
import Banner_6 from "../../../images/banner-6.png";
import Banner_7 from "../../../images/banner-7.png";
import Offer_1 from "../../../images/offer_1.png";
import Offer_2 from "../../../images/offer_2.png";
import OurValues from "./OurValues/OurValues";
import Blogs from "./Blogs/Blogs";

const Banner_Panel_1 = [Banner_1, Banner_2, Banner_3];
const Banner_Panel_2 = [Offer_1, Offer_2];
const Banner_Panel_3 = [Banner_5, Banner_6, Banner_7];

function Home() {
  return (
    <>
      <HeroCarousel />
      <Banner images={Banner_Panel_1} />
      <PopularCategories />
      <Banner images={Banner_Panel_2} />
      <NewArrivals />
      <Banner images={Banner_Panel_3} />
      <HotDeals />
      <OurValues />
      <Blogs />
    </>
  );
}

export default Home;
