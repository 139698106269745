import axios from "axios";

import {
  PRODUCTS_FAIL,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  CLEAR_ERRORS,
} from "../constants/productsConstants";

const productsAction = () => {
  return {
    // Get All Products
    getProducts:
      (
        category = "all",
        currentPage = 1,
        sortBy = "Relevance",
        resultPerPage = 5
      ) =>
      async (dispatch) => {
        try {
          dispatch({ type: PRODUCTS_REQUEST });
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/products/${category}?page=${currentPage}&sortby=${sortBy}&resultperpage=${resultPerPage}`
          );

          dispatch({
            type: PRODUCTS_SUCCESS,
            payload: data,
          });
        } catch (error) {
          dispatch({
            type: PRODUCTS_FAIL,
            payload: error.response.data.message,
          });
        }
      },

    // Get Product Details
    getProductDetails: (id) => async (dispatch) => {
      try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST });
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/product/${id}`
        );

        dispatch({
          type: PRODUCT_DETAILS_SUCCESS,
          payload: data.product,
        });
      } catch (error) {
        dispatch({
          type: PRODUCT_DETAILS_FAIL,
          payload: error.response.data.message,
        });
      }
    },

    // Use to clear all errors
    clearErrors: (dispatch) => {
      dispatch({ type: CLEAR_ERRORS });
    },
  };
};

export default productsAction;
