import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./views/layouts/Home/Home";
import Products from "./views/layouts/Products/Products";
import ProductDetails from "./views/layouts/ProductDetails/ProductDetails";
import PageNotFound from "./views/layouts/PageNotFound/PageNotFound";
import AboutUs from "./views/layouts/AboutUs/AboutUs";
import BlogDetails from "./views/layouts/BlogDetails/BlogDetails";

const Router = () => {
  return (
    <Routes>
      <Route exact path="/blog/:id" element={<BlogDetails />}></Route>
      <Route exact path="/about-us" element={<AboutUs />}></Route>
      <Route exact path="/product/:id" element={<ProductDetails />}></Route>
      <Route exact path="/products/:category" element={<Products />}></Route>
      <Route exact path="/" element={<Home />}></Route>
      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
  );
};

export default Router;
