import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import CardGroup from "react-bootstrap/CardGroup";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import ProductCard from "../../../components/ProductCard/ProductCard";
import productsAction from "../../../../redux/actions/productsAction";
import Loader from "../../../components/Loader/Loader";
import "./NewArrivals.css";

const NewArrivals = () => {
  const dispatch = useDispatch();

  const { loading, products } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(productsAction().getProducts("all", 1, "What's New", 5));
  }, [dispatch]);

  return (
    <section className="new_arrival_section section_spacing">
      <SectionTitle
        title="New Arrivals"
        subtitle="Add New Products to weekly line up"
      />
      <Container fluid>
        <CardGroup>
          {loading ? (
            <Loader />
          ) : (
            products &&
            products.map((product, index) => (
              <ProductCard product={product} key={index} />
            ))
          )}
        </CardGroup>
      </Container>
    </section>
  );
};

export default NewArrivals;
