import React from "react";
import "./Rating.css";

const Rating = ({ rating }) => {
  let value = [];

  for (let i = 1; i <= rating; i++) {
    value.push("★");
  }
  for (let i = 1; i <= 5 - rating; i++) {
    value.push("☆");
  }

  return (
    <div className="rating">
      <div className="stars">
        {value.map((star, index) => (
          <span key={index}>{star}</span>
        ))}
      </div>
      <span className="value">({rating}.0)</span>
    </div>
  );
};

export default Rating;
