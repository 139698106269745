import React from "react";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import Container from "react-bootstrap/esm/Container";
import Slider from "react-slick";
import {
  faBoxOpen,
  faCreditCard,
  faQuestion,
  faTruckMoving,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./OurValues.css";

let details = [
  {
    icon: faQuestion,
    title: "Customer Satisfaction",
    description:
      "We prioritize our customers' needs and strive to exceed their expectations by providing personalized attention, exceptional service, and high-quality products.",
  },
  {
    icon: faBoxOpen,
    title: "Quality Products",
    description:
      "We offer a wide range of high-quality gift items that are unique, thoughtful and tailored to the preferences of our customers.",
  },
  {
    icon: faCreditCard,
    title: "Innovation",
    description:
      "We are always on the lookout for new and innovative gift ideas that cater to the evolving tastes and preferences of our customers.",
  },
  {
    icon: faTruckMoving,
    title: "Community Engagement",
    description:
      "We are committed to giving back to the community by participating in charitable events and supporting local causes.",
  },
];

let settings = {
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1350,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const OurValues = () => {
  return (
    <section className="our_values_section section_spacing">
      <SectionTitle
        title="Our Values"
        subtitle="Our mission is achieved through our values"
      />
      <Container fluid>
        <Slider {...settings}>
          {details &&
            details.map((detail, index) => (
              <div className="values_detail" key={index}>
                <FontAwesomeIcon icon={detail.icon} />
                <h3>{detail.title}</h3>
                <p>{detail.description}</p>
              </div>
            ))}
        </Slider>
      </Container>
    </section>
  );
};

export default OurValues;
