import React from "react";
import Card from "react-bootstrap/Card";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Banner from "../../../images/product_page_banner.jpg";
import "./PageTitle.css";

const PageTitle = ({ details }) => {
  return (
    <Card className="page_title my-2 text-dark">
      <Card.Img src={Banner} alt="Card image" />
      <Card.ImgOverlay>
        <Card.Title>{details.title}</Card.Title>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active className="text-capitalize">
            {details.breadcrumb}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Card.ImgOverlay>
    </Card>
  );
};

export default PageTitle;
