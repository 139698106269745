import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Container from "react-bootstrap/Container";
import Pagination from "react-js-pagination";
import productsAction from "../../../redux/actions/productsAction";
import Loader from "../../components/Loader/Loader";
import ProductCard from "../../components/ProductCard/ProductCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import NoData from "../../../images/no-data.svg";
import PageTitle from "../../components/PageTitle/PageTitle";
import "./Products.css";

let sortByOptions = [
  "Price (Lowest First)",
  "Relevance",
  "Discount",
  "Price (Highest First)",
  "What's New",
];

const Products = () => {
  const { category } = useParams();

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("Relevance");

  const setCurrentPageNumber = (e) => {
    setCurrentPage(e);
  };

  let resultPerPage = 20;

  const {
    loading = true,
    products,
    productsCount,
    updatedProductsCount,
  } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(
      productsAction().getProducts(category, currentPage, sortBy, resultPerPage)
    );
  }, [dispatch, category, currentPage, sortBy, resultPerPage]);

  return (
    <section className="products_page_section">
      <Container fluid>
        <PageTitle
          details={{
            title: `${category === "all" ? "All Products" : category}`,
            breadcrumb: "Products",
          }}
        />
        <div className="products_container">
          {loading ? (
            <Loader />
          ) : !products || (products && products.length === 0) ? (
            <Card className="no_data_container">
              <Card.Img src={NoData}></Card.Img>
              <Card.Body>
                <Card.Title>Products Not Found 🙁 ... !</Card.Title>
              </Card.Body>
            </Card>
          ) : (
            <div>
              <div className="sort_by_box">
                <p>SORT BY</p>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={sortBy}
                  variant="outline-secondary"
                  size="sm"
                >
                  {sortByOptions.map((option, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => setSortBy(option)}
                    >
                      {option}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <CardGroup>
                {products &&
                  products.map((product, index) => (
                    <ProductCard product={product} key={index} />
                  ))}
              </CardGroup>
              {resultPerPage < updatedProductsCount && (
                <div className="pagination_box">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={resultPerPage}
                    totalItemsCount={productsCount}
                    onChange={setCurrentPageNumber}
                    pageRangeDisplayed={3}
                    firstPageText={<FontAwesomeIcon icon={faAnglesLeft} />}
                    prevPageText={<FontAwesomeIcon icon={faAngleLeft} />}
                    nextPageText={<FontAwesomeIcon icon={faAngleRight} />}
                    lastPageText={<FontAwesomeIcon icon={faAnglesRight} />}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default Products;
