import React from "react";
import "./SectionTitle.css";

const SectionTitle = ({ title, subtitle }) => {
  return (
    <div className="section_titel">
      <h1 className="t1">{title}</h1>
      <p className="t3">{subtitle}</p>
      <hr />
    </div>
  );
};

export default SectionTitle;
