import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Logo from "../../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcAmazonPay,
  faCcApplePay,
  faCcMastercard,
  faCcPaypal,
  faCcVisa,
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <Container fluid>
        <div className="footer_top">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <div className="social_media">
            <FontAwesomeIcon icon={faFacebookF} />
            <FontAwesomeIcon icon={faTwitter} />
            <FontAwesomeIcon icon={faInstagram} />
            <FontAwesomeIcon icon={faYoutube} />
          </div>
        </div>
        <div className="footer_center">
          <div className="bio">
            <h4>World’s Gift Mall</h4>
            <p>
              Pioneer of ‘WORLD GIFTS & CARDS’ of RAJKOT – MR. RAJESH PAREKH &
              BHAVESH PAREKH has now entered into thier new ventur in the name
              of ‘W O R L D’S G I F T M A L L’ at AMIN MARG, RAJKOT with very
              big spacious showroom of more than 5000 varieties at reasonable
              prices.
            </p>
          </div>
          <div className="contact_us">
            <h4>Contact US</h4>
            <p className="d-flex align-items-start flex-column-md">
              <b>Phone numbers :</b>
              <p className="m-0 px-1 d-md-flex flex-column">
                <span>9104040407, </span>
                <span>8511141115</span>
              </p>
            </p>
            <p>
              <b>E-mail :</b> worldsgiftmall@gmail.com
            </p>
            <p>
              <b>Business hours :</b> <br />
              Monday - Saturday 9.30 AM - 9.00 PM <br />
              Sunday 9.30AM - 1.00 PM
            </p>
          </div>
          <div className="categories">
            <h4>Categories</h4>
            <ul>
              <li>
                <Link to={"/products/figurine"}>Figurine</Link>
              </li>
              <li>
                <Link to={"/products/lighting"}>Lighting</Link>
              </li>
              <li>
                <Link to={"/products/gifts-for-him"}>Gifts For Him</Link>
              </li>
              <li>
                <Link to={"/products/decore"}>Decore</Link>
              </li>
              <li>
                <Link to={"/products/games"}>Games</Link>
              </li>
              <li>
                <Link to={"/products/personal-care"}>Personal Care</Link>
              </li>
            </ul>
          </div>
          <div className="categories">
            <h4>Categories</h4>
            <ul>
              <li>
                <Link to={"/products/gifts-for-her"}>Gifts For Her</Link>
              </li>
              <li>
                <Link to={"/products/furniture"}>Furniture</Link>
              </li>
              <li>
                <Link to={"/products/cards"}>Cards</Link>
              </li>
              <li>
                <Link to={"/products/chocolates"}>Chocolates</Link>
              </li>
              <li>
                <Link to={"/products/stationery"}>Stationery</Link>
              </li>
              <li>
                <Link to={"/products/beauty"}>Beauty</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="copy_write">©2023 All rights reserved.</div>
          <div className="payment">
            <FontAwesomeIcon icon={faCcVisa} />
            <FontAwesomeIcon icon={faCcMastercard} />
            <FontAwesomeIcon icon={faCcPaypal} />
            <FontAwesomeIcon icon={faCcApplePay} />
            <FontAwesomeIcon icon={faCcAmazonPay} />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
