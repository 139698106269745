import React from "react";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import "./BlogDetails.css";

const BlogDetails = () => {
  const location = useLocation();
  const blog = location.state.data;

  return (
    <section className="blogs_details_page">
      <Container fluid>
        <PageTitle details={{ title: "Blog", breadcrumbItems: "Blog" }} />
        <div className="blog_details">
          <h2 className="mb-4">
            <b>{blog.title}</b>
          </h2>
          <div className="image">
            <img src={blog.image} alt="" />
          </div>
          <div className="details">
            <p>{blog.details}</p>
            {blog.paragraph.map((detail, index) => (
              <div className="my-3" key={index}>
                {detail.heading && (
                  <span>
                    <b>{detail.heading} : </b>
                  </span>
                )}
                <span>{detail.content}</span>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default BlogDetails;
