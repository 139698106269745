import React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Rating from "../Rating/Rating";
import "./ProductCard.css";

const ProductCard = ({ product }) => {
  let dayDifference;
  if (product) {
    dayDifference = Math.round(
      (new Date().getTime() - new Date(product.updatedAt).getTime()) /
        (1000 * 3600 * 24)
    );
  }

  return (
    product && (
      <Link to={`/product/${product._id}`} className="product_card_container text-decoration-none">
        <Card className="product_card">
          <div className="image_container">
            {product.images.length > 0 ? (
              <Card.Img src={product.images[0].url} />
            ): (<Card.Img src="" />)}
          </div>
          <Card.ImgOverlay className="p-0">
            {product.discount >= 20 ? (
              <span className="overlay_tag bg-hot">Hot Deal</span>
            ) : (
              dayDifference < 3 && (
                <span className="overlay_tag bg-success">New</span>
              )
            )}
          </Card.ImgOverlay>
          <Card.Body>
            <Card.Subtitle className="category">
              {product.category}
            </Card.Subtitle>
            <Card.Title>{product.name}</Card.Title>
            <Rating rating={product.rating} />
            <div className="price">
              <s>&#x20b9;{product.price}</s>
              <p>
                &#x20b9;
                {Math.round(
                  product.price - (product.price * product.discount) / 100
                )}
              </p>
              <span className="tag bg-danger">-{product.discount}%</span>
            </div>
          </Card.Body>
        </Card>
      </Link>
    )
  );
};

export default ProductCard;
