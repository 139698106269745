const PRODUCTS_REQUEST = "PRODUCT_REQUEST";
const PRODUCTS_SUCCESS = "PRODUCT_SUCCESS";
const PRODUCTS_FAIL = "PRODUCT_FAIL";

const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

const CLEAR_ERRORS = "CLEAR_ERRORS";

export {
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  CLEAR_ERRORS,
};
