import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import Decors from "../../../../images/Category/decore.jpg";
import Figurine from "../../../../images/Category/figurine.png";
import Lighting from "../../../../images/Category/lighting.png";
import Gifts_For_Her from "../../../../images/Category/gifts-for-her.jpg";
import Furniture from "../../../../images/Category/furniture.jpg";
import "./PopularCategories.css";

let settings = {
  infinite: true,
  speed: 1500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 740,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};
let popularCategoriesData = [
  {
    image: Decors,
    category: "Decors",
    stock: 25,
    link: "/products/decore",
  },
  {
    image: Figurine,
    category: "Figurines",
    stock: 12,
    link: "/products/figurines",
  },
  {
    image: Gifts_For_Her,
    category: "Gifts For Her",
    stock: 17,
    link: "/products/gifts-for-her",
  },
  {
    image: Furniture,
    category: "Furniture",
    stock: 15,
    link: "/products/furniture",
  },
  {
    image: Lighting,
    category: "Lighting",
    stock: 10,
    link: "/products/lighting",
  },
];

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slick_carousel_btn slick_carousel_next_btn"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slick_carousel_btn slick_carousel_prev_btn"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
}

const PopularCategories = () => {
  return (
    <section className="popular_category_section section_spacing">
      <SectionTitle
        title="Popular Category"
        subtitle="Add Popular Category to weekly line up"
      />
      <Container fluid className="slider_carousel_container">
        <Slider {...settings}>
          {popularCategoriesData.map((item, index) => (
            <div className="category_card" key={index}>
              <div className="product_img">
                <img src={item.image} alt="" />
              </div>
              <div className="details">
                <h2>{item.category}</h2>
                <p>{item.stock} Products</p>
              </div>
              <Link to={item.link}>
                Shop Now
                <FontAwesomeIcon icon={faPlayCircle} className="faPlayCircle" />
              </Link>
            </div>
          ))}
        </Slider>
      </Container>
    </section>
  );
};

export default PopularCategories;
